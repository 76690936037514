export const DEPARTMENTS = {
  administration: {
    value: 'administración',
    label: 'Administración',
  },
  accounts: {
    value: 'cuentas',
    label: 'Cuentas',
  },
  data: {
    value: 'datos',
    label: 'Datos',
  },
  finance: {
    value: 'finanzas',
    label: 'Finanzas',
  },
  operations: {
    value: 'operaciones',
    label: 'Operaciones',
  },
  humanResources: {
    value: 'recursos humanos',
    label: 'Recursos humanos',
  },
  external: {
    value: 'external',
    label: 'Externo',
  },
};
